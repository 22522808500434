let savedScrollPosition = null

export function preventScrolling (modifier = '') {
  const prefix = modifier ? `${modifier}-` : ''
  savedScrollPosition = document.documentElement.scrollTop || document.body.scrollTop
  document.documentElement.classList.add(`${prefix}prevent-scrolling`)
}

export function enableScrolling (modifier = '') {
  const prefix = modifier ? `${modifier}-` : ''
  document.documentElement.classList.remove(`${prefix}prevent-scrolling`)

  /* We only scroll if the scroll position was at least saved once, or each page scrolls to the top always */
  if (savedScrollPosition !== null) {
    setTimeout(() => (document.documentElement || document.body).scrollTo(0, savedScrollPosition || 0))
  }
}
