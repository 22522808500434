import Component from '../../../assets/scripts/modules/component'
import { getCookie, setCookie } from '../../../assets/scripts/utilities/simple-cookies'

const randomBetweenMinAndMax = (min = 0, max = 1) => Math.floor(Math.random() * (max - min + 1) + min)

const COLORS = ['green', 'orange', 'blue', 'pink', 'yellow']

export default class DocumentComponent extends Component {
  init () {
    this.initAnswerColor()
  }

  initAnswerColor () {
    if (!this.element.classList.contains('document--answer')) {
      return
    }

    const cookie = getCookie('cookie-answer-color')
    let color = cookie

    if (!cookie || COLORS.indexOf(cookie) === -1) {
      color = COLORS[randomBetweenMinAndMax(0, COLORS.length - 1)]
      setCookie('cookie-answer-color', color)
    }

    this.element.setAttribute('data-color', color)
  }
}

window.addEventListener('init-immediate', () => {
  document.documentElement.instance = document.documentElement.instance || new DocumentComponent(document.documentElement)
})
