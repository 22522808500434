
// Konami code
// Pass a callback function to execute when the code has been entered
export default function konamiCode (callback) {
  let count = 0

  window.addEventListener('keydown', function (event) {
    const longCode = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a']

    count = longCode[count] === event.key ? count + 1 : 0

    if (count > 9) {
      callback()
    }

    count %= 10
  })
}
