
import Component from '../../../assets/scripts/modules/component'

export default class MenuBarContentsComponent extends Component {
  init () {
    this.menuButtons = [...this.element.querySelectorAll('.button--menu, .button--close-menu')]
    this.menuButtons.forEach(button => button.addEventListener('click', () => {
      window.dispatchEvent(new CustomEvent('toggle-menu-overlay'))
    }))
  }
}

window.addEventListener('init-load', () => [...document.querySelectorAll('.menu-bar-contents')].forEach(element => {
  element.instance = element.instance || new MenuBarContentsComponent(element)
}))
