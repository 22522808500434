
import breakpoints from '../../../assets/scripts/modules/breakpoints'
import Component from '../../../assets/scripts/modules/component'
import { setCookie } from '../../../assets/scripts/utilities/simple-cookies'

const randomBetweenMinAndMax = (min = 0, max = 1) => Math.floor(Math.random() * (max - min + 1) + min)

const COLORS = ['green', 'orange', 'blue', 'pink', 'yellow']
const LAST_USED_COLORS = {}

const RESIZE_DEBOUNCE_TIME = 200
const UPDATE_TEXT_AFTER_ANIMATION_TIME = 400

class GridBallComponent extends Component {
  init () {
    this.question = this.element.getAttribute('data-question')
    this.questionMedium = this.element.getAttribute('data-question-medium')
    this.word = this.element.getAttribute('data-word')
    this.categories = this.element.getAttribute('data-categories')

    this.gridBallsInstance = this.element.closest('.homepage-balls')?.instance

    this.isStatic = this.element.classList.contains('is-static')
    this.size = 'small'
    this.expanded = false
    this.hidden = false

    this.contentContainer = this.element.querySelector('.grid-ball__content-wrapper')
    this.questionContainer = this.element.querySelector('.grid-ball__question')

    this.initColor()
    this.initClickHandler()
    this.initQuestionText()
    this.initResizeHandler()
  }

  initColor () {
    if (this.isStatic) {
      return
    }

    const { top, left } = this.element.getBoundingClientRect()
    const availableColors = COLORS.filter(color => color !== LAST_USED_COLORS[top] && color !== LAST_USED_COLORS[left])
    const color = availableColors[randomBetweenMinAndMax(0, availableColors.length - 1)]

    LAST_USED_COLORS[top] = color
    LAST_USED_COLORS[left] = color

    this.element.setAttribute('data-color', color)
  }

  // Button click handler which handles the expand/collapse of the ball
  initClickHandler () {
    this.element.addEventListener('click', evt => {
      evt?.stopPropagation()

      if (this.size === 'large' || this.expanded) {
        if (this.isStatic) {
          // window.dispatchEvent(new Event('toggle-question-flow-overlay'))
          window.location.hash = 'stel-je-vraag'
        } else {
          setCookie('cookie-answer-color', this.element.getAttribute('data-color'))
          window.location.href = this.element.getAttribute('data-link-href')
        }
      } else {
        this.gridBallsInstance?.gridBalls.filter(ball => !!ball.classList.contains('is-expanded')).forEach(ball => ball.instance.setExpanded(false))
        this.setExpanded(true)
      }
    })
  }

  setExpanded (expanded) {
    this.element.classList.toggle('is-expanded', expanded)
    this.expanded = expanded
    this.setQuestionText()
  }

  // Showing the initial question text
  initQuestionText () {
    const content = this.expanded || this.size === 'large' || (this.size === 'medium' && breakpoints.isNotebook()) ? this.question : this.word

    this.questionContainer.textContent = content
  }

  // Debounced resize handler for showing correct question content when the window is resized
  initResizeHandler () {
    this.resizeTimeout = null
    window.addEventListener('resize', () => {
      clearTimeout(this.resizeTimeout)
      this.resizeTimeout = setTimeout(() => this.initQuestionText(), RESIZE_DEBOUNCE_TIME)
    })
  }

  // Handling the content of the question when the ball is being expanded/collapsed
  setQuestionText () {
    if (!this.question || !this.contentContainer || !this.questionContainer) {
      return
    }

    // Hide question text to start animation
    this.contentContainer.style.opacity = 0

    // Wait for CSS grid animation to finish before updating the content and showing text
    // Added 50ms to the animation duration because balls would bounce back and forth if time is equal
    setTimeout(() => {
      const questionMedium = this.questionMedium || this.question
      const content = this.size === 'medium' && breakpoints.isNotebook() ? questionMedium : this.expanded || this.size === 'large' ? this.question : this.word

      this.questionContainer.textContent = content
      this.contentContainer.style.opacity = 1
    }, UPDATE_TEXT_AFTER_ANIMATION_TIME)
  }

  // Resize ball
  setSize (size) {
    this.size = size
    this.element.classList.remove('size--small')
    this.element.classList.remove('size--medium')
    this.element.classList.remove('size--large')
    this.element.classList.add(`size--${size}`)
    this.setQuestionText()
  }

  // Show/hide ball based on filter value
  applyFilter (filterValue) {
    if (this.isStatic) {
      return
    }

    this.hidden = filterValue !== 'all' && (this.categories.indexOf(filterValue) === -1)
    this.element.classList.toggle('hidden', this.hidden)
    this.element.setAttribute('aria-hidden', this.hidden ? 'true' : 'false')
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.grid-ball').forEach((element) => {
  element.instance = element.instance || new GridBallComponent(element)
}))
