
import Component from '../../../assets/scripts/modules/component'
import { setCookie } from '../../../assets/scripts/utilities/simple-cookies'

const randomBetweenMinAndMax = (min = 0, max = 1) => Math.floor(Math.random() * (max - min + 1) + min)

const COLORS = ['green', 'orange', 'blue', 'pink', 'yellow']
const LAST_USED_COLORS = {}

export default class QuestionBallComponent extends Component {
  init () {
    this.initColor()
  }

  initColor () {
    if (this.element.classList.contains('question-ball--submit')) {
      return
    }

    const { top, left } = this.element.getBoundingClientRect()
    const availableColors = COLORS.filter(color => color !== LAST_USED_COLORS[top] && color !== LAST_USED_COLORS[left])
    const color = availableColors[randomBetweenMinAndMax(0, availableColors.length - 1)]

    LAST_USED_COLORS[top] = color
    LAST_USED_COLORS[left] = color

    this.element.setAttribute('data-color', color)
    this.element.addEventListener('click', () => setCookie('cookie-answer-color', color))
  }
}

window.addEventListener('init-load', () => [...document.querySelectorAll('.question-ball')].forEach(element => {
  element.instance = element.instance || new QuestionBallComponent(element)
}))
