
export default class EmitterComponent {
  constructor (element, controller = null) {
    this.element = element
    this.listeners = {}

    this.init()
    // We return this object to bind the instance.
    return this
  }

  addEventListener (event, callback) {
    if (!(event in this.listeners)) {
      this.listeners[event] = []
    }

    if (!this.listeners[event].includes(callback)) {
      this.listeners[event].push(callback)
    }
  }

  removeEventListener (event, callback) {
    if (!(event in this.listeners)) {
      return
    }

    this.listeners[event] = this.listeners[event].filter(listener => listener !== callback)
  }

  emit (event, data) {
    if (!(event in this.listeners)) {
      return
    }

    this.listeners[event].forEach(callback => callback(data))
  }

  static init () {
    // Override this method
  }
}
