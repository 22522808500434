
// Insert external script async
export default function insertScript (id = '', src = '') {
  return new Promise((resolve, reject) => {
    if (!id || !src) {
      reject(new Error('No ID or SRC given.'))
    } else if (document.getElementById(id)) {
      resolve()
    }

    const script = document.createElement('script')
    const timestamp = Math.floor(Date.now() / 1000)

    document.body.appendChild(script)

    script.onload = () => {
      script.onload = null
      resolve()
    }

    script.setAttribute('data-timestamp', timestamp)
    script.id = id
    script.src = src
  })
}
