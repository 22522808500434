
// import setAllExternalLinksToTargetBlank from './utilities/external-links-target-blank.js'
import konamiCode from './utilities/konami-code.js'

// Import in reverse order from stylesheets
require('../../components/structures/skip-to-main/skip-to-main.js')
require('../../components/structures/question-form-flow/question-form-flow.js')
require('../../components/structures/question-flow-overlay/question-flow-overlay.js')
require('../../components/structures/menu-overlay/menu-overlay.js')
require('../../components/structures/document/document.js')
require('../../components/structures/cookie-bar/cookie-bar.js')
require('../../components/organisms/modal/modal.js')
require('../../components/organisms/location-list/location-list.js')
require('../../components/organisms/homepage-balls/homepage-balls.js')
require('../../components/organisms/article-cookies/article-cookies.js')
require('../../components/molecules/menu-bar-contents/menu-bar-contents.js')
require('../../components/molecules/form/form.js')
require('../../components/atoms/video-embed/video-embed.js')
require('../../components/atoms/question-ball/question-ball.js')
require('../../components/atoms/picture/picture.js')
require('../../components/atoms/grid-ball/grid-ball.js')
require('../../components/atoms/description-list/description-list.js')

console.log('\n %cMade with %c♥%c by Fabrique \n', 'font: 16px serif;', 'font: 13px serif; color: #f00;', 'font: 16px serif;')

// setAllExternalLinksToTargetBlank()

// There are 4 load events:
// - 'init-immediate': (compiles when the script is loaded; blocks rendering)
// - 'init-load': (on DOMContentLoaded event; does not block rendering)
// - 'init-after-load': (on Load event, slightly after DOMContentLoaded)
// - 'init-delayed-load': (after Load event, with a slight delay, for iframes and such)
// Usually, the 'init-load' event will suffice.
window.dispatchEvent(new CustomEvent('init-immediate'))
window.addEventListener('DOMContentLoaded', () => window.dispatchEvent(new CustomEvent('init-load')))
window.addEventListener('load', () => window.dispatchEvent(new CustomEvent('init-after-load')))
window.addEventListener('load', () => window.setTimeout(() => window.dispatchEvent(new CustomEvent('init-delayed-load')), 1000))

// Focus stuff
document.addEventListener('keydown', () => document.documentElement.classList.add('key-pressed'))
document.addEventListener('mousedown', () => document.documentElement.classList.remove('key-pressed'))
document.addEventListener('touchstart', () => document.documentElement.classList.remove('key-pressed'))

// This gives you vh-like values, but without scrollbars and addressbar and such
// This allows usage of document height / width in CSS
// NOTE: Only turn this on when needed - very heavy when resizing
// NOTE: Resize observer only from Safari 13.1 onwards, so back to window.onresize.
// new ResizeObserver(() => {
// ...
// }).observe(document.documentElement)
/*
if (document.documentElement.classList.contains('is-ios') || document.documentElement.classList.contains('is-android')) {
  const onResizeHandler = () => {
    window.requestAnimationFrame(() => {
      document.documentElement.style.setProperty('--100vw', `${document.documentElement.clientWidth}px`)
      document.documentElement.style.setProperty('--100vh', `${document.documentElement.clientHeight}px`)
      document.documentElement.style.setProperty('--100dw', `${document.documentElement.offsetWidth}px`)
      document.documentElement.style.setProperty('--100dh', `${document.documentElement.offsetHeight}px`)
    })
  }
  window.addEventListener('resize', () => onResizeHandler())
  onResizeHandler()
}
*/

// Show debug styles on L hotkey and grid on G hotkey when document is focussed.
// if (WEBPACK_ENV.ENV === 'development') {
if (window.location.href.indexOf('127.0.0.1') !== -1 || window.location.href.indexOf('0.0.0.0') !== -1) {
  document.addEventListener('keydown', event => {
    if (event.target !== document.body) {
      return
    }

    if ((event.ctrlKey || event.metaKey) && event.key === 'l') {
      document.documentElement.classList.toggle('debug-layout')
    }

    if ((event.ctrlKey || event.metaKey) && event.key === 'i') {
      const isInverted = document.documentElement.classList.contains('document--inverted')

      document.documentElement.classList.toggle('document--regular', isInverted)
      document.documentElement.classList.toggle('document--inverted', !isInverted)
    }

    document.cookie = `debug-layout=${document.documentElement.classList.contains('debug-layout')}; path=/;`
  })

  document.documentElement.classList.toggle('debug-layout', document.cookie.indexOf('debug-layout=true') !== -1)
}

window.addEventListener('load', () => document.documentElement.classList.add('has-loaded'))

konamiCode(() => {
  document.documentElement.classList.add('konami-code')
  window.KONAMI_ACTIVATED = true
  // window.dispatchEvent(new Event('resize'))

  const randomBetweenMinAndMax = (min = 0, max = 1) => Math.floor(Math.random() * (max - min + 1) + min)

  const soundBalloonRubbing = new Audio('/static/media/konami-sounds/balloon-rubbing.mp3')
  soundBalloonRubbing.load()

  soundBalloonRubbing.preservesPitch = false
  soundBalloonRubbing.loop = true
  soundBalloonRubbing.volume = 1

  const balls = [...document.querySelectorAll('.question-ball')].filter(ball => !ball.classList.contains('.question-ball--submit'))
  balls.forEach(ball => {
    let moveTimeout

    ball.addEventListener('mouseenter', () => {
      soundBalloonRubbing.playbackRate = (randomBetweenMinAndMax(75, 125) / 100)
    })

    ball.addEventListener('mousemove', () => {
      soundBalloonRubbing.play()
      window.clearTimeout(moveTimeout)
      moveTimeout = window.setTimeout(() => soundBalloonRubbing.pause(), 25)
    })

    ball.addEventListener('mouseleave', () => {
      soundBalloonRubbing.pause()
    })
  })
})
