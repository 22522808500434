import Component from '../../../assets/scripts/modules/component'
import sleep from '../../../assets/scripts/utilities/sleep'
import ModalComponent from '../../organisms/modal/modal'
import QuestionFlowOverlayComponent from '../question-flow-overlay/question-flow-overlay'

const HASH = '#stel-je-vraag'
const INITIAL_SECTION = 'form-section'

async function postData (url = '', data = {}, csrfToken = '') {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrfToken
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  })

  const json = await response.json()

  if (response.status < 300) {
    return json // parses JSON response into native JavaScript objects
  }

  throw new Error(JSON.stringify(json))
}

class Section {
  constructor (element, questionFormFlow) {
    this.element = element
    this.questionFormFlow = questionFormFlow

    this.init()
  }

  init () {
    //
  }

  async activate () {
    //
  }

  async deactivate () {
    //
  }
}

class RegularModalSection extends Section {
  init () {
    super.init()

    const modal = this.element.querySelector('.modal')

    modal.instance = modal.instance || new ModalComponent(modal)
    modal.instance.addEventListener('modal-toggled', isOpen => (window.location.hash = isOpen ? HASH : ''))

    this.modal = modal
  }

  async activate () {
    super.activate()

    this.modal.instance.toggleActive(false, true, true)
  }

  async deactivate () {
    super.deactivate()

    this.modal.instance.toggleActive(true, true, true)
  }
}

class FormSection extends Section {
  init () {
    super.init()

    this.active = false
    this.changeTimer = null

    const questionOverlay = this.element.querySelector('.question-form-flow__question-overlay')
    questionOverlay.instance = questionOverlay.instance || new QuestionFlowOverlayComponent(questionOverlay)

    questionOverlay.instance.addEventListener('question-flow-overlay-toggled', isOpen => this.onToggle(isOpen))
    questionOverlay.instance.addEventListener('question-flow-overlay-submit', values => this.onSubmit(values))

    this.questionOverlay = questionOverlay
  }

  async activate () {
    super.activate()

    this.active = true
    setTimeout(() => this.questionOverlay.instance.toggleOverlay(false, true), 100)
  }

  async deactivate () {
    super.deactivate()

    this.questionOverlay.instance.toggleOverlay(true, true)
    await sleep(100)
  }

  onToggle (isOpen) {
    if (this.active) {
      if (this.changeTimer) {
        clearTimeout(this.changeTimer)
      }

      if (!isOpen) {
        window.location.hash = ''
      }

      this.changeTimer = setTimeout(() => {
        window.location.hash = isOpen ? HASH : ''
      }, 100)
    }
  }

  onSubmit (values) {
    values.forEach(({ name, value }) => {
      this.questionFormFlow.state[name] = value
    })

    if (!this.questionFormFlow.state.question.endsWith('?')) {
      this.questionFormFlow.state.question += '?'
    }

    this.active = false

    this.questionOverlay.instance.toggleOverlay(true, true)
    this.questionFormFlow.toggleSection('check-section')
  }
}

class CheckSection extends RegularModalSection {
  init () {
    super.init()

    this.formInput = this.element.querySelector('form input[name="accept-terms"]')
    this.formInput.addEventListener('change', event => {
      this.questionFormFlow.state.acceptTerms = event.target.checked
    })
  }

  async activate () {
    super.activate()

    this.element.querySelector('[data-role="show-email-address"]').innerText = this.questionFormFlow.state.email
    this.element.querySelector('.question-form-flow__question-ball .question-ball__title').innerText = this.questionFormFlow.state.question
    this.element.querySelector('.question-form-flow__question-ball')?.setAttribute('data-color', sessionStorage.getItem('question-flow-overlay-color') || 'orange')
  }
}

export default class QuestionFormFlowComponent extends Component {
  init () {
    this.activeElement = null
    this.contrastButton = this.element.querySelector('.button--contrast')
    this.currentSection = null

    this.state = {
      section: null,
      question: 'Hoe lang brandt onze zon nog?',
      name: '',
      email: 'rubenvanbambost@gmail.com',
      age: '',
      newsletter: false,
      acceptTerms: false
    }

    this.sections = {
      'form-section': new FormSection(this.element.querySelector('.question-form-flow__form-section'), this),
      'check-section': new CheckSection(this.element.querySelector('.question-form-flow__check-section'), this),
      'done-section': new RegularModalSection(this.element.querySelector('.question-form-flow__done-section'), this)
    }

    this.sections['check-section'].element?.querySelector('.question-form-flow__question-ball')?.setAttribute('data-color', sessionStorage.getItem('question-flow-overlay-color') || 'orange')
    this.sections['done-section'].element?.setAttribute('data-color', sessionStorage.getItem('question-flow-overlay-color') || 'orange')

    this.element.querySelector('.question-form-flow__send-button').addEventListener('click', event => this.onSendButtonClick(event))

    window.addEventListener('hashchange', event => this.toggleActive(window.location.hash !== HASH))
    this.toggleActive(window.location.hash !== HASH)
  }

  onSendButtonClick (event) {
    event.preventDefault()
    event.stopPropagation()
    this.state.newsletter = this.element.querySelector('input[name="newsletter"]').checked

    try {
      this.submit()
    } catch (error) {
      console.log(error)
    }
  }

  async toggleActive (forceClose = undefined) {
    const currentState = this.element.classList.contains('question-form-flow--active')
    const opening = forceClose === undefined ? !currentState : !forceClose

    await this.toggleSection(opening ? INITIAL_SECTION : null)
    this.element.classList[opening ? 'add' : 'remove']('question-form-flow--active')
  }

  async toggleSection (section) {
    if (this.state.section === section) {
      return
    }

    if (this.state.section) {
      await this.sections[this.state.section].deactivate()
      this.element.classList.remove(`question-form-flow--at-${this.state.section}`)
    }

    this.state.section = section

    if (section) {
      this.element.classList.add(`question-form-flow--at-${this.state.section}`)
      await this.sections[this.state.section].activate()
    }
  }

  async submit () {
    const { question, name, age, email, newsletter } = this.state
    const data = { question, email, newsletter }

    if (!this.state.acceptTerms) {
      alert('Je moet akkoord gaan met de voorwaarden.')
      return
    }

    if (this.state.name) {
      data.name = name
    }

    if (this.state.age) {
      data.age = age
    }

    try {
      await postData('/api/hundredyears/add-question/', data, this.element.dataset.csrfToken)
    } catch (error) {
      window.alert(error)
      this.toggleSection('form-section')
      return
    }

    this.toggleSection('done-section')
  }
}

window.addEventListener('init-load', () => [...document.querySelectorAll('.question-form-flow')].forEach(element => {
  element.instance = element.instance || new QuestionFormFlowComponent(element)
}))
