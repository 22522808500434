import { createFocusTrap } from 'focus-trap'
import EmitterComponent from '../../../assets/scripts/modules/emitter-component'
import { enableScrolling, preventScrolling } from '../../../assets/scripts/utilities/prevent-scrolling'

// function questionIsRequired (questionElement) {
//   return !![...questionElement.classList.values()].find(cl => cl.endsWith('--required'))
// }
//
// function questionIsValid (questionElement) {
//   const input = questionElement.querySelector('input, textarea')
//   return input.checkValidity() && !(questionElement.customValidators || []).find(not(v => v(input.value)))
// }
//
// const not = f => (...args) => !f(...args)

export default class QuestionFlowOverlayComponent extends EmitterComponent {
  init () {
    this.activeElement = null

    this.wrapper = this.element.querySelector('.question-flow-overlay__wrapper')
    this.ballOuter = this.element.querySelector('.question-flow-overlay__ball-outer')
    this.starters = [...this.element.querySelectorAll('.question-flow-overlay__starter')]
    this.buttonClose = this.element.querySelector('.button--close')
    this.buttonsNext = [...this.element.querySelectorAll('.button--next')]
    this.scrollContainer = this.element.querySelector('.question-flow-overlay__scroll-container')
    this.scrollObject = this.element.querySelector('.question-flow-overlay__scroll-object')

    this.sectionPreview = this.element.querySelector('.question-flow-overlay__section--preview')
    this.sectionOne = this.element.querySelector('.question-flow-overlay__section--one')
    this.sectionTwo = this.element.querySelector('.question-flow-overlay__section--two')
    this.sections = [this.sectionPreview, this.sectionOne, this.sectionTwo]
    this.sectionCurrent = null

    this.questionInput = this.element.querySelector('input[name="question"]')
    this.postviewText = this.element.querySelector('.question-flow-overlay__postview-text')

    // this.initInputValidations()
    this.initOverlay()
    this.initAccessibility()

    this.initStarters()
    this.initSections()
    this.initQuestionPostview()

    this.buttonClose?.addEventListener('click', () => this.toggleOverlay(true))
    this.buttonsNext.forEach(button => button.addEventListener('click', event => this.onButtonNextClick(event)))

    this.ballOuter?.setAttribute('aria-role', 'button')
    this.ballOuter?.setAttribute('tabindex', 0)
    this.ballOuter?.addEventListener('click', () => this.onBallOuterClick())
    this.ballOuter?.addEventListener('keydown', event => event.key === 'Enter' && this.onBallOuterClick())

    window.addEventListener('resize', () => this.onResizeHandler())
    window.requestAnimationFrame(() => this.onResizeHandler())

    this.reset()
  }

  initInputValidations () {
    const questionInput = this.element.querySelector('input[name="question"]')

    if (!questionInput) {
      // return
    }

    // NOTE: Disabled - custom validator messages are never shown reliably and this is confusing.
    // this.sections[1].customValidators = [value => value.trim().split(' ').length >= 3]

    // NOTE: This doesn't work reliably - the error message keeps popping up at every keypress until valid. There is no way to work around this.
    // questionInput.pattern = '([a-zA-Z]+\s){2,}([a-zA-Z]+)'
    // questionInput.addEventListener('invalid', () => questionInput.setCustomValidity('Gebruik minimaal 3 woorden.'))
    // questionInput.addEventListener('change', () => { questionInput.setCustomValidity('') })
  }

  initOverlay () {
    window.addEventListener('toggle-question-flow-overlay', event => this.toggleOverlay(event.detail?.forceClose || false))
  }

  toggleOverlay (forceClose = false, forceThroughAnimation = false) {
    const closing = forceClose || document.documentElement.classList.contains('question-flow-overlay-visible')

    if (!forceThroughAnimation && (document.documentElement.classList.contains('question-flow-overlay-closing') || document.documentElement.classList.contains('question-flow-overlay-opening'))) {
      return
    }

    if (document.documentElement.classList.contains('question-flow-overlay-visible') === !closing) {
      return
    }

    document.documentElement.classList.toggle('question-flow-overlay-visible', !closing)
    closing ? enableScrolling('question-flow-overlay') : preventScrolling('question-flow-overlay')

    if (!closing) {
      this.setAriaHiddenOnOtherElements(true)
      this.hideOverlayTabbableElements(false)
      this.overlayFocusTrap.activate()

      this.starters[this.starters.length - 1]?.querySelector('button')?.dispatchEvent(new Event('click'))
    } else {
      this.overlayFocusTrap.deactivate()
    }

    if (closing) {
      document.documentElement.classList.add('question-flow-overlay-closing')

      window.setTimeout(() => {
        document.documentElement.classList.remove('question-flow-overlay-closing')
        this.setAriaHiddenOnOtherElements(false)
        this.hideOverlayTabbableElements(true)
        this.reset()
      }, 350)
    } else {
      document.documentElement.classList.add('question-flow-overlay-opening')

      window.setTimeout(() => {
        document.documentElement.classList.remove('question-flow-overlay-opening')
        this.onResizeHandler()
      })
    }

    this.emit('question-flow-overlay-toggled', !closing)
  }

  async initAccessibility () {
    this.setAriaHiddenOnOtherElements(false)
    this.hideOverlayTabbableElements(true)

    this.overlayFocusTrap = createFocusTrap(this.element, {
      onActivate: () => {
        this.activeElement = document.activeElement
        this.element.querySelector('.question-flow-overlay__starter-button, input:not(:disabled), select:not(:disabled), textarea:not(:disabled), a, button:not(:disabled)')?.focus()
      },
      onDeactivate: () => {
        this.toggleOverlay(true)

        if (!this.activeElement) {
          return
        }

        this.activeElement.focus()
        this.activeElement = null
      },
      escapeDeactivates: true,
      clickOutsideDeactivates: true,
      returnFocusOnDeactivate: false
    })

    this.toggleOverlay(true)
  }

  setAriaHiddenOnOtherElements (hidden = true) {
    const content = document.querySelector('.content')
    const overlay = document.querySelector('.question-flow-overlay')

    if (content) {
      content.setAttribute('aria-hidden', hidden)
    }

    if (overlay) {
      overlay.setAttribute('aria-hidden', !hidden)
    }
  }

  hideOverlayTabbableElements (hidden = true) {
    const modalDialogTabbableElements = [...this.element.querySelectorAll('a[href], button, input, select, textarea')]

    modalDialogTabbableElements.forEach(element => element.classList.toggle('is-hidden', hidden))
    modalDialogTabbableElements.forEach(element => hidden ? element.setAttribute('tabindex', -1) : element.removeAttribute('tabindex'))
  }

  initStarters () {
    if (!this.sectionPreview || !this.starters.length) {
      return
    }

    this.starters.forEach(starter => {
      const button = starter.querySelector('button')
      button?.addEventListener('click', event => this.onStarterButtonClick(event, button))
    })
  }

  onStarterButtonClick (event, button) {
    event.preventDefault()

    if (!this.sectionPreview || !button || !document.documentElement.classList.contains('question-flow-overlay-visible')) {
      return
    }

    const input = this.sectionPreview.querySelector('input')
    const color = button.parentNode.getAttribute('data-color')
    const prefillText = button.parentNode.getAttribute('data-prefill-text')

    if (!input) {
      return
    }

    input.value = prefillText
    this.ballOuter?.setAttribute('data-color', color)
    sessionStorage.setItem('question-flow-overlay-color', color)
    this.starters.forEach(starter => {
      starter.classList.remove('question-flow-overlay__starter--active')
      starter.querySelector('button')?.removeAttribute('disabled')
    })
    button.parentNode.classList.add('question-flow-overlay__starter--active')
    button.setAttribute('disabled', 'disabled')
  }

  initSections () {
    if (!this.initSections.length) {
      return
    }

    this.sections.forEach(section => {
      const input = section.querySelector('input, textarea')
      section.addEventListener('click', event => this.onSectionClick(event, section))
      input.addEventListener('keydown', event => event.key === 'Enter' && this.onButtonNextClick(event))
    })
  }

  onSectionClick (event, section) {
    if (!this.sections.length) {
      return
    }

    const input = section.querySelector('input, textarea')

    if (section === this.sectionPreview) {
      event?.preventDefault()

      const nextSection = this.sectionOne
      const nextInput = nextSection.querySelector('input, textarea')

      if (nextInput) {
        nextInput.value = input.value
      }

      this.onButtonNextClick()
    } else if (section !== this.sectionCurrent) {
      event?.preventDefault()

      this.sections.forEach(section => section.classList.remove('question-flow-overlay__section--active'))
      section.classList.add('question-flow-overlay__section--active')
      input?.focus()
      section.querySelector('input[type="checkbox"]')?.toggleAttribute('checked')
      this.sectionCurrent = section
    }
  }

  onButtonNextClick (event) {
    event?.preventDefault()

    if (!this.sections.length) {
      return
    }

    const input = this.sectionCurrent.querySelector('input, textarea')
    const isValid = input.checkValidity()

    if (!isValid) {
      input.reportValidity()
      return
    }

    const nextSection = this.sections[this.sections.indexOf(this.sectionCurrent) + 1]

    if (nextSection) {
      this.sectionCurrent.classList.remove('question-flow-overlay__section--active')
      nextSection.classList.add('question-flow-overlay__section--active')
      nextSection.querySelector('input, textarea')?.focus()
      this.sectionCurrent = nextSection
    } else {
      const values = []
      this.sections.forEach(section => section.querySelectorAll('input, textarea').forEach(element => values.push({ name: element.name, value: element.checked || (!['checkbox', 'radio'].includes(element.type) && element.value) })))
      this.emit('question-flow-overlay-submit', values)
    }

    this.starters.forEach(starter => {
      starter.classList.add('question-flow-overlay__starter--hidden')
      starter.querySelector('button')?.setAttribute('disabled', 'disabled')
    })

    this.ballOuter.classList.add('question-flow-overlay__ball-outer--expanded')
    this.ballOuter.removeAttribute('tabindex')

    this.onResizeHandler()
  }

  onBallOuterClick () {
    if (!this.sectionCurrent || this.sectionCurrent !== this.sectionPreview) {
      return
    }

    this.onSectionClick(null, this.sectionCurrent)
  }

  reset () {
    this.sections.forEach(section => {
      if (section !== this.previewSection) {
        section.classList.remove('question-flow-overlay__section--active')
        const input = section.querySelector('input, textarea')

        if (input) {
          input.value = ''
        }
      }
    })

    this.sectionCurrent = this.sectionPreview
    this.sectionCurrent.classList.add('question-flow-overlay__section--active')

    this.starters.forEach(starter => {
      starter.classList.remove('question-flow-overlay__starter--hidden')
      starter.querySelector('button')?.removeAttribute('disabled')
    })

    this.starters[this.starters.length - 1]?.querySelector('button')?.dispatchEvent(new Event('click'))

    this.ballOuter.classList.remove('question-flow-overlay__ball-outer--expanded')
    this.ballOuter.setAttribute('tabindex', 0)
  }

  onResizeHandler () {
    if (!this.scrollContainer || !this.scrollObject) {
      return
    }

    const scrollHeight = this.scrollContainer.offsetHeight
    const offsetHeight = this.scrollObject.offsetHeight // - (parseInt(this.scrollObject.style.paddingTop) || 0) - (parseInt(this.scrollObject.style.paddingBottom) || 0)
    const difference = (scrollHeight - offsetHeight) / 2

    if (scrollHeight > offsetHeight) {
      this.scrollObject.style.marginTop = `${Math.floor(difference)}px`
      this.scrollObject.style.marginBottom = `${Math.ceil(difference)}px`
    } else {
      this.scrollObject.style.marginTop = 0
      this.scrollObject.style.marginBottom = 0
    }
  }

  initQuestionPostview () {
    if (!this.questionInput || !this.postviewText) {
      return
    }

    const suffix = this.postviewText.getAttribute('data-suffix') || ''

    this.questionInput.addEventListener('change', event => {
      this.postviewText.innerText = this.questionInput.value + suffix
    })
  }
}

window.addEventListener('init-load', () => [...document.querySelectorAll('.question-flow-overlay')].forEach(element => {
  element.instance = element.instance || new QuestionFlowOverlayComponent(element)
}))
