
import Component from '../../../assets/scripts/modules/component'

window.DEBUG_PHASER = false

const DEFAULT_PHASER_CONFIG = {
  debug: true,
  type: 2, // Phaser.WEBGL
  // pixelArt: true,
  // backgroundColor: '#4eb3e7',
  transparent: true,
  physics: { default: 'matter', matter: { debug: window.DEBUG_PHASER } },
  scale: { mode: 0, autoCenter: 1 },
  banner: false,
  audio: { noAudio: true }
}

class HomepageBallsComponent extends Component {
  init () {
    this.initAsync()
  }

  async initAsync () {
    this.container = this.element.querySelector('.homepage-balls__canvas-container')
    this.toggleWrapper = this.element.querySelector('.homepage-balls__toggle-container')
    this.toggle = this.element.querySelector('.homepage-balls__toggle')
    this.mobileFilterDropdown = this.element.querySelector('.homepage-balls__filters-dropdown select')
    this.mobileFilterDropdownOptions = [...this.element.querySelectorAll('.homepage-balls__filters-dropdown option')]
    this.mobileFilterButton = this.element.querySelector('.homepage-balls__filters-dropdown-btn')
    this.landscapeFilterButtons = [...this.element.querySelectorAll('.homepage-balls__filters-landscape .homepage-balls__filter-button')]
    this.zoomOutButton = this.element.querySelector('.homepage-balls__zoom-out-button')
    this.zoomInButton = this.element.querySelector('.homepage-balls__zoom-in-button')
    this.ballGrid = this.element.querySelector('.homepage-balls__results-grid')
    this.gridBalls = [...this.element.querySelectorAll('.grid-ball')]

    this.containerWidth = this.element.clientWidth * window.devicePixelRatio
    this.containerHeight = this.element.clientHeight * window.devicePixelRatio

    this.listOfQuestions = []
    this.zoomLevel = 1

    this.unwrapGrid = () => { }

    this.initToggle()
    this.initZoomButtons()

    this.mobileFilterButton?.addEventListener('click', () => this.onMobileFilterButtonClick())
    this.landscapeFilterButtons.forEach(filter => filter.addEventListener('click', () => this.onLandscapeFilterButtonClick(filter)))

    await this.initBallGrid()
    await this.initPhaser()

    this.onZoomButtonClick(this.zoomLevel, true)
  }

  initToggle () {
    if (!this.toggleWrapper || !this.toggle) {
      return
    }

    this.toggle.addEventListener('click', () => this.onToggleClick())
  }

  onToggleClick () {
    const willShowGrid = this.toggle.getAttribute('aria-checked') === 'false'

    this.toggle.setAttribute('aria-checked', willShowGrid ? 'true' : 'false')
    this.element.classList.toggle('show-grid', willShowGrid)
    this.toggleWrapper.classList.toggle('show-grid', willShowGrid)

    if (!this.game) {
      return
    }

    if (willShowGrid) {
      this.game.pause()
      this.game.scene.game.input.mouse.enabled = false
      // Reinitialize color, since balls are now visible
      this.element.querySelectorAll('.grid-ball').forEach(b => b.instance?.initColor())
    } else {
      this.game.resume()
      this.game.scene.game.input.mouse.enabled = true
    }
  }

  async onMobileFilterButtonClick () {
    const { wrapGrid } = (await import('../../../assets/scripts/plugins/animate-css-grid')).default()

    this.unwrapGrid()
    this.gridBalls.forEach(ball => ball.instance.applyFilter(this.mobileFilterDropdown.value))
    this.unwrapGrid = wrapGrid(this.ballGrid, { duration: 350, stagger: 0 }).unwrapGrid

    this.landscapeFilterButtons.forEach(filter => filter.classList.toggle('button--selected', filter.dataset.category === this.mobileFilterDropdown.value))
  }

  onLandscapeFilterButtonClick (filter) {
    if (!filter || !this.mobileFilterDropdownOptions.indexOf(filter.dataset.category)) {
      return
    }

    this.mobileFilterDropdown.value = filter.dataset.category
    this.onMobileFilterButtonClick()
  }

  initZoomButtons () {
    if (!this.zoomOutButton || !this.zoomInButton) {
      return
    }

    this.zoomOutButton.toggleAttribute('disabled', this.zoomLevel === 0)
    this.zoomInButton.toggleAttribute('disabled', this.zoomLevel === 2)

    this.zoomOutButton.addEventListener('click', () => this.onZoomButtonClick(-1))
    this.zoomInButton.addEventListener('click', () => this.onZoomButtonClick(1))
  }

  onZoomButtonClick (value = 0, forceLevel = false) {
    this.zoomLevel = forceLevel ? value : this.zoomLevel + value
    this.gridBalls.forEach(ball => ball.instance.setSize(this.zoomLevel === 0 ? 'small' : this.zoomLevel === 1 ? 'medium' : 'large'))

    this.zoomOutButton.toggleAttribute('disabled', this.zoomLevel === 0)
    this.zoomInButton.toggleAttribute('disabled', this.zoomLevel === 2)
  }

  async initBallGrid () {
    if (!this.ballGrid || !this.gridBalls || !this.gridBalls.length) {
      return
    }

    const { wrapGrid } = (await import('../../../assets/scripts/plugins/animate-css-grid')).default()
    this.unwrapGrid = wrapGrid(this.ballGrid, { duration: 350, stagger: 0 }).unwrapGrid

    this.ballGrid.addEventListener('click', () => this.gridBalls.filter(ball => !!ball.element?.classList.contains('expanded')).forEach(ball => ball.instance.setExpanded(false)))
  }

  async initPhaser () {
    if (!this.container) {
      return
    }

    const { Phaser, BallsScene } = (await import('../../../assets/scripts/plugins/homepage-balls')).default()
    const config = { ...DEFAULT_PHASER_CONFIG, parent: this.container, width: this.container.clientWidth * window.devicePixelRatio, height: this.container.clientHeight * window.devicePixelRatio, resolution: window.devicePixelRatio, scene: [BallsScene] }

    this.gridBalls.forEach(ball => {
      this.listOfQuestions.push({ question: ball.dataset.question, word: ball.dataset.word, author: ball.dataset.author, categories: ball.dataset.categories, isStatic: ball.classList.contains('is-static'), linkHref: ball.dataset.linkHref, linkTitle: ball.dataset.linkTitle })
    })

    this.game = new Phaser.Game(config)
    this.game.scene.start('balls-scene', { listOfQuestions: this.listOfQuestions, container: this.container })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.homepage-balls').forEach((element) => {
  element.instance = element.instance || new HomepageBallsComponent(element)
}))
