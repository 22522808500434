
import Component from '../../../assets/scripts/modules/component'

class PictureComponent extends Component {
  init () {
    this.img = this.element.querySelector('img')
    this.sourceElements = this.element.querySelectorAll('source')

    if (!this.img || !this.sourceElements.length) {
      return
    }

    this.sources = []
    this.sourceElements.forEach(source => this.sources.push({ element: source, src: source.srcset, media: source.media || 'screen', style: source.getAttribute('style') }))

    // Apply the source style attributes to enable per-breakpoint object-position and fit values
    this.addMediaQueryListeners()
    const source = this.sources.find(source => window.matchMedia(source.media).matches)
    if (source) {
      this.applySource(source)
    }

    // Fire load event immediately if the image has already loaded
    if (this.img.currentSrc) {
      this.onImageLoad()
    } else {
      this.img.addEventListener('load', () => this.onImageLoad())
    }
  }

  onImageLoad () {
    this.element.classList.add('picture--loaded')
  }

  applySource (source) {
    if (!this.img) {
      return
    }

    this.img.style = source.style
  }

  addMediaQueryListeners () {
    if (!this.sources.length) {
      return
    }

    this.sources.forEach(source => window.matchMedia(source.media).addListener(result => result.matches && this.applySource(source)))
  }
}

window.addEventListener('init-load', () => [...document.querySelectorAll('.picture')].forEach(element => {
  element.instance = element.instance || new PictureComponent(element)
}))

// So we can instantiate in cloned Swiper slides
window.PictureComponent = PictureComponent
