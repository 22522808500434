import { createFocusTrap } from 'focus-trap'
import Component from '../../../assets/scripts/modules/component'
import { enableScrolling, preventScrolling } from '../../../assets/scripts/utilities/prevent-scrolling'

export default class MenuOverlayComponent extends Component {
  init () {
    this.activeElement = null
    this.contrastButton = this.element.querySelector('.button--contrast')
    this.background = this.element.querySelector('.menu-overlay__background')

    this.initOverlay()
    this.initAccessibility()
    this.initContrastButton()
  }

  initOverlay () {
    window.addEventListener('toggle-menu-overlay', event => this.toggleOverlay(event.detail?.forceClose || false))
  }

  toggleOverlay (forceClose = false) {
    const closing = forceClose || document.documentElement.classList.contains('menu-overlay-visible')

    if (document.documentElement.classList.contains('menu-overlay-closing') || document.documentElement.classList.contains('menu-overlay-opening')) {
      return
    }

    document.documentElement.classList.toggle('menu-overlay-visible', !closing)
    closing ? enableScrolling('menu') : preventScrolling('menu')

    if (!closing) {
      this.setAriaHiddenOnOtherElements(true)
      this.hideOverlayTabbableElements(false)
      this.overlayFocusTrap.activate()
    } else {
      this.overlayFocusTrap.deactivate()
    }

    if (closing) {
      document.documentElement.classList.add('menu-overlay-closing')

      window.setTimeout(() => {
        document.documentElement.classList.remove('menu-overlay-closing')
        this.setAriaHiddenOnOtherElements(false)
        this.hideOverlayTabbableElements(true)
      }, 350)
    } else {
      document.documentElement.classList.add('menu-overlay-opening')

      window.setTimeout(() => {
        document.documentElement.classList.remove('menu-overlay-opening')
      })
    }
  }

  async initAccessibility () {
    this.setAriaHiddenOnOtherElements(false)
    this.hideOverlayTabbableElements(true)

    this.overlayFocusTrap = createFocusTrap(this.element, {
      onActivate: () => {
        this.activeElement = document.activeElement
        this.element.querySelector('.link')?.focus()
      },
      onDeactivate: () => {
        this.toggleOverlay(true)

        if (!this.activeElement) {
          return
        }

        this.activeElement.focus()
        this.activeElement = null
      },
      escapeDeactivates: true,
      clickOutsideDeactivates: true,
      returnFocusOnDeactivate: false
    })

    // Set focusable content to non-focusable on initial load
    this.toggleOverlay(true)
  }

  setAriaHiddenOnOtherElements (hidden = true) {
    const content = document.querySelector('.content')
    const overlay = document.querySelector('.menu-overlay')

    if (content) {
      content.setAttribute('aria-hidden', hidden)
    }

    if (overlay) {
      overlay.setAttribute('aria-hidden', !hidden)
    }
  }

  hideOverlayTabbableElements (hidden = true) {
    const modalDialogTabbableElements = [...this.element.querySelectorAll('a[href], button, input, select, textarea')]

    modalDialogTabbableElements.forEach(element => element.classList.toggle('is-hidden', hidden))
    modalDialogTabbableElements.forEach(element => hidden ? element.setAttribute('tabindex', -1) : element.removeAttribute('tabindex'))
  }

  initContrastButton () {
    if (!this.contrastButton) {
      return
    }

    this.contrastButton.addEventListener('click', () => this.toggleContrast())
    this.setContrast(document.cookie.indexOf('high-contrast=true') !== -1)
  }

  toggleContrast () {
    const isEnabled = document.cookie.indexOf('high-contrast=true') !== -1
    this.setContrast(!isEnabled)
    this.toggleOverlay(true)
    window.location.reload()
  }

  setContrast (contrast) {
    const titleOn = this.contrastButton.getAttribute('data-title-on')
    const titleOff = this.contrastButton.getAttribute('data-title-off')
    const buttonSpan = this.contrastButton.querySelector('span')

    if (buttonSpan && titleOn && titleOff) {
      buttonSpan.innerText = contrast ? titleOn : titleOff
    }

    document.documentElement.classList.toggle('document--high-contrast', contrast)
    document.cookie = `high-contrast=${contrast};path=/;max-age=31536000`
  }
}

window.addEventListener('init-load', () => [...document.querySelectorAll('.menu-overlay')].forEach(element => {
  element.instance = element.instance || new MenuOverlayComponent(element)
}))
