
import Component from '../../../assets/scripts/modules/component'

export default class SkipToMainComponent extends Component {
  init () {
    this.button = this.element.querySelector('.button')
    this.button.addEventListener('click', event => this.clickHandler(event))
  }

  clickHandler (event) {
    const target = document.querySelector('main, [role="main"], #main')
    const rect = target.getBoundingClientRect()

    window.scrollTo(0, rect.y)

    event?.preventDefault()
    event?.stopPropagation()

    window.requestAnimationFrame(() => this.button.blur())
  }
}

window.addEventListener('init-load', () => [...document.querySelectorAll('.skip-to-main')].forEach(element => {
  element.instance = element.instance || new SkipToMainComponent(element)
}))
