
import Component from '../../../assets/scripts/modules/component'
import insertScript from '../../../assets/scripts/utilities/insert-script-async'
import randomHash from '../../../assets/scripts/utilities/random-hash'
import { getCookie } from '../../../assets/scripts/utilities/simple-cookies'

const DEFAULT_VIMEO_OPTIONS = {
  autopause: true,
  autoplay: true,
  background: false, // Hide controls and autoplay
  byline: false, // Show byline
  color: 'ffffff',
  loop: false,
  muted: false,
  playsinline: false, // Set to true to play inline on some mobile devices
  portrait: false, // Show the video portrait
  speed: false, // Show speed controls
  title: false, // Show title
  transparent: true
}

const DEFAULT_YOUTUBE_OPTIONS = {
  host: 'https://www.youtube.com',
  width: 640,
  height: 360,
  playerVars: {
    autoplay: 1,
    hd: 1,
    showinfo: 0,
    modestbranding: 1,
    iv_load_policy: 3,
    rel: 0,
    origin: `${window.location.protocol}//${window.location.host}`
  }
}

export default class VideoEmbedComponent extends Component {
  init () {
    this.container = this.element.querySelector('.video-embed__container')
    this.hitTarget = this.element.querySelector('.video-embed__hit-target')
    this.autoplay = this.element.classList.contains('video-embed--autoplay')
    this.videoId = this.element.getAttribute('data-video-id') || ''
    this.videoType = this.element.getAttribute('data-video-type') || ''

    if (!this.container || !this.hitTarget || !this.videoId || !this.videoType) {
      return
    }

    this.iframeId = `player-${randomHash()}`
    this.player = null

    window.addEventListener('cookie-consent', () => this.checkCookie())
    this.checkCookie()
  }

  initVideo () {
    if (!this.hitTarget) {
      return
    }

    this.hitTarget.addEventListener('click', this.onHitTargetClick.bind(this))

    if (this.autoplay) {
      window.addEventListener('init-delayed-load', event => this.hitTarget.click(event))
    }
  }

  onHitTargetClick (event) {
    event.preventDefault()

    if (this.videoType === 'vimeo') {
      this.startVimeo()
    } else {
      this.startYouTube()
    }
  }

  async startVimeo () {
    if (this.player) {
      this.player.playVideo() // Test if this is correct
    } else {
      if (!window.Vimeo) {
        await insertScript('vimeo-api-script', 'https://player.vimeo.com/api/player.js').catch(err => console.error(err))
      }

      this.insertVimeoContainer()
    }
  }

  async startYouTube () {
    if (this.player) {
      this.player.playVideo()
    } else {
      if (!window.YT) {
        await insertScript('youtube-api-script', 'https://www.youtube.com/iframe_api').catch(err => console.error(err))
      }

      this.insertYouTubeContainer()
    }
  }

  insertVimeoContainer () {
    if (!window.Vimeo) {
      console.error('Vimeo is not initialised correctly')
    }

    this.hitTarget.parentNode.removeChild(this.hitTarget)

    this.player = new window.Vimeo.Player(this.container, {
      ...DEFAULT_VIMEO_OPTIONS,
      id: this.videoId // Can be either the ID or URL to the video
    })

    this.element.classList.add('video-embed--loaded')
  }

  insertYouTubeContainer () {
    if (!window.YT) {
      console.error('YouTube is not initialised correctly')
    }

    if (!this.hitTarget || this.player) {
      return
    }

    const div = document.createElement('div')

    div.id = this.iframeId
    div.className = 'video-embed__iframe'

    this.hitTarget.parentNode.replaceChild(div, this.hitTarget)
    this.hitTarget = null

    const afterLoad = () => {
      this.player = new window.YT.Player(this.iframeId, {
        ...DEFAULT_YOUTUBE_OPTIONS,
        videoId: this.videoId,
        events: {
          onReady: () => {
            this.element.classList.add('video-embed--loaded')
            this.player.setPlaybackQuality('hd1080')

            if (this.element.getAttribute('data-autoplay') !== null) {
              this.player.playVideo()
            }
          }
        }
      })

      window.youtubeLoaded = true
    }

    if (!window.youtubeLoaded) {
      window.onYouTubeIframeAPIReady = afterLoad
    } else {
      afterLoad()
    }
  }

  checkCookie () {
    const cookie = getCookie('cookie-consent')

    if (!cookie || cookie.split(',').indexOf('embeds') === -1) {
      this.hitTarget.setAttribute('hidden', 'hidden')
      this.element.classList.add('video-embed--cookies-not-accepted')
    } else {
      this.hitTarget.removeAttribute('hidden')
      this.element.classList.remove('video-embed--cookies-not-accepted')

      this.initVideo()
    }
  }
}

window.addEventListener('init-delayed-load', () => [...document.querySelectorAll('.video-embed')].forEach(element => {
  element.instance = element.instance || new VideoEmbedComponent(element)
}))
