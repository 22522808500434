// jshint module: true
// This one doesn't use dependencies. If other subdomains should be included, use `complex-cookies` as a drop-in replacement.

export function getCookie (name) {
  const parts = `; ${document.cookie}`.split(`; ${name}=`)
  return parts.length < 2 ? undefined : parts.pop().split(';').shift()
}

export function setCookie (name, value, expiryDays, domain = '', path = '/') {
  const expiryDate = new Date(new Date().setDate(new Date().getDate() + (expiryDays || 365))).toUTCString()
  const cookie = [`${name}=${value}`]

  cookie.push(`Expires=${expiryDate}`)
  cookie.push(`Path=${path}`)
  cookie.push(`Domain=${domain || location.hostname}`)

  if (window.location.protocol === 'https:') {
    cookie.push(`Secure`)
  }

  cookie.push(`SameSite=Lax`)

  document.cookie = cookie.join('; ').trim()
}
