import { createFocusTrap } from 'focus-trap'
import EmitterComponent from '../../../assets/scripts/modules/emitter-component'
import { enableScrolling, preventScrolling } from '../../../assets/scripts/utilities/prevent-scrolling'

export default class ModalComponent extends EmitterComponent {
  init () {
    this.activeElement = null

    this.initModal()
    this.initAccessibility()
  }

  initModal () {
    window.addEventListener('toggle-modal', event => this.toggleActive(event.detail?.forceClose || false))
    this.element.querySelector('.modal__button-close').addEventListener('click', () => this.toggleActive(true))
  }

  toggleActive (forceClose = false, forceThroughAnimation = false, silent = false) {
    const closing = forceClose || this.element.classList.contains('modal--active')

    if (!forceThroughAnimation && (document.documentElement.classList.contains('modal-closing') || document.documentElement.classList.contains('modal-opening'))) {
      return
    }

    if (this.element.classList.contains('modal--active') === !closing) {
      return
    }

    this.element.classList.toggle('modal--active', !closing)
    closing ? enableScrolling('modal') : preventScrolling('modal')

    if (!silent) {
      this.emit('modal-toggled', !closing)
    }

    if (!closing) {
      this.setAriaHiddenOnOtherElements(true)
      this.hideOverlayTabbableElements(false)
      this.focusTrap.activate()
    } else {
      this.focusTrap.deactivate()
    }

    if (closing) {
      document.documentElement.classList.add('modal-closing')

      window.setTimeout(() => {
        document.documentElement.classList.remove('modal-closing')
        this.setAriaHiddenOnOtherElements(false)
        this.hideOverlayTabbableElements(true)
      }, 350)
    } else {
      document.documentElement.classList.add('modal-opening')

      window.setTimeout(() => {
        document.documentElement.classList.remove('modal-opening')
      }, 350)
    }
  }

  async initAccessibility () {
    this.setAriaHiddenOnOtherElements(false)
    this.hideOverlayTabbableElements(true)

    this.focusTrap = createFocusTrap(this.element, {
      onActivate: () => {
        this.activeElement = document.activeElement
        this.element.querySelector('button')?.focus()
      },
      onDeactivate: () => {
        this.toggleActive(true)

        if (!this.activeElement) {
          return
        }

        this.activeElement.focus()
        this.activeElement = null
      },
      escapeDeactivates: true,
      clickOutsideDeactivates: true,
      returnFocusOnDeactivate: false
    })

    // Set focusable content to non-focusable on initial load
    this.toggleActive(true)
  }

  setAriaHiddenOnOtherElements (hidden = true) {
    const content = document.querySelector('.content')
    const modal = document.querySelector('.modal')

    if (content) {
      content.setAttribute('aria-hidden', hidden)
    }

    if (modal) {
      modal.setAttribute('aria-hidden', !hidden)
    }
  }

  hideOverlayTabbableElements (hidden = true) {
    const modalDialogTabbableElements = [...this.element.querySelectorAll('a[href], button, input, select, textarea')]

    modalDialogTabbableElements.forEach(element => element.classList.toggle('is-hidden', hidden))
    modalDialogTabbableElements.forEach(element => hidden ? element.setAttribute('tabindex', -1) : element.removeAttribute('tabindex'))
  }
}

window.addEventListener('init-load', () => [...document.querySelectorAll('.modal')].forEach(element => {
  element.instance = element.instance || new ModalComponent(element)
}))
