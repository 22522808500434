
import Component from '../../../assets/scripts/modules/component'

const randomBetweenMinAndMax = (min = 0, max = 1) => Math.floor(Math.random() * (max - min + 1) + min)

const COLORS = ['green', 'orange', 'blue', 'pink', 'yellow']

class LocationListComponent extends Component {
  init () {
    this.initColors()
  }

  initColors () {
    this.locations = [...this.element.querySelectorAll('.location-list__location')]

    if (!this.locations.length) {
      return
    }

    let lastColor = ''
    this.locations.forEach(location => {
      const filteredColors = COLORS.filter(color => color !== lastColor)
      const color = filteredColors[randomBetweenMinAndMax(0, filteredColors.length - 1)]

      location.setAttribute('data-color', color)
      lastColor = color
    })
  }
}

window.addEventListener('init-load', () => [...document.querySelectorAll('.location-list')].forEach(element => {
  element.instance = element.instance || new LocationListComponent(element)
}))
