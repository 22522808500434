
import breakpoints from '../../../assets/scripts/modules/breakpoints'
import Component from '../../../assets/scripts/modules/component'

class DescriptionListComponent extends Component {
  init () {
    this.initDisclosure()
  }

  initDisclosure () {
    this.disclosure = this.element.querySelector('.description-list__disclosure')
    this.button = this.element.querySelector('.description-list__disclosure-button')
    this.region = this.element.querySelector('.description-list__disclosure-content-container')

    if (!this.disclosure || !this.button || !this.region) {
      return
    }

    this.button.addEventListener('click', () => this.toggleRegion())
  }

  toggleRegion () {
    if (!this.disclosure || !this.button || !this.region) {
      return
    }

    // const openOtherRegionsButtons = [...document.querySelectorAll('.description-list__disclosure-button[aria-expanded="true"]')]
    // openOtherRegionsButtons.forEach(button => button.click())

    if (this.button.getAttribute('aria-expanded') === 'true') {
      this.region.setAttribute('hidden', 'hidden')
      this.region.setAttribute('aria-hidden', true)
      this.button.setAttribute('aria-expanded', false)
      this.disclosure.classList.remove('discription-list__disclosure--expanded')
    } else {
      this.region.removeAttribute('hidden')
      this.region.setAttribute('aria-hidden', false)
      this.button.setAttribute('aria-expanded', true)
      this.disclosure.classList.add('discription-list__disclosure--expanded')

      const rect = this.region.getBoundingClientRect()
      window.scroll({ top: (window.scrollY + rect.top) - (window.innerHeight / 3), left: 0, behavior: breakpoints.prefersReducedMotion() ? 'auto' : 'smooth' })
    }
  }
}

window.addEventListener('init-load', () => [...document.querySelectorAll('.description-list')].forEach(element => {
  element.instance = element.instance || new DescriptionListComponent(element)
}))
